import React from 'react';
import CheckAuth from '../hooks/CheckAuth';
import '../styles/rules.css'; // Import the CSS file for rules page

const RulesPage = () => {
  return (
    <div className="rules-container">
      <h1>Rules and Guidelines</h1>
      <section>
        <h2>Welcome to CTF Master!</h2>
        <p>
          This is a place to learn, compete, and have fun while solving exciting security challenges. To maintain fairness, security, and a positive experience for everyone, please follow these simple rules:
        </p>
      </section>

      <section>
        <h2>General Guidelines</h2>
        <h3>Focus on the Challenges:</h3>
        <p>Your target is the challenge itself, not the platform or other players. Please do not attack the competition infrastructure or other participants in any way.</p>
        <h3>No Automated Tools or Scripts:</h3>
        <p>Tools like sqlmap, DirBuster, nmap, Metasploit, and other automated scanners are prohibited. This is a learning platform — take the time to solve the challenges manually.</p>
        <h3>No Brute Forcing:</h3>
        <p>Flags are meant to be discovered through solving challenges, not through brute force or random guessing.</p>
        <h3>Play Fair:</h3>
        <p>Do not share flags with others. Avoid explicitly sharing solutions or cheating in any way. Collaboration is welcome, but let others learn by solving the challenges themselves.</p>
        <h3>Seek Help the Right Way:</h3>
        <p>If you’re stuck, ask for help by explaining what you've tried and sharing any errors or results you've encountered. Avoid asking for direct solutions or hints that give away the challenge.</p>
      </section>

      <section>
        <h2>Flag Format</h2>
        <p>Flags in this competition typically follow the format:</p>
        <code>CTFMASTER&#123;...&#125;</code>
        <p>However, the exact contents inside the curly braces <code>{'{}'}</code> may vary depending on the challenge. Here's what you need to know:</p>
        <h3>Allowed Characters:</h3>
        <p>While some flags use only hexadecimal characters (0-9 and a-f), others may include:</p>
        <ul>
          <li>Uppercase and lowercase letters (A-Z, a-z)</li>
          <li>Digits (0-9)</li>
          <li>Special characters (!@#$%^&*, etc., if relevant to the challenge)</li>
        </ul>
        <h3>General Structure:</h3>
        <p>Flags will always start with <code>CTFMASTER{'{'}</code> and end with <code>{'}'}</code>, but the content inside the curly braces will vary depending on the challenge.</p>
      </section>

      <section>
        <h2>Community and Support</h2>
        <p>We’re here to help you learn and grow. Connect with the community via:</p>
        <ul>
          <li>Challenge Assistance: If a challenge has an author noted, feel free to DM them with thoughtful, specific questions.</li>
        </ul>
      </section>

      <section>
        <h2>Our Commitment to Your Privacy</h2>
        <p>We take your privacy seriously and have designed this platform with minimal data collection in mind. Here’s what you should know:</p>
        <h3>Secure Data Storage:</h3>
        <p>Any data we store is kept secure and is only what’s necessary for the platform to function.</p>
        <h3>Minimal Information Collection:</h3>
        <p>We don’t collect more than we need. For example, user accounts only store the essential details required for login and competition participation.</p>
      </section>

      <section>
        <h2>Legal Notice</h2>
        <p>By participating in this Capture The Flag competition ("CTF"), you agree to:</p>
        <ul>
          <li>Follow the rules outlined above.</li>
          <li>Respect the platform and community.</li>
          <li>Understand that any breach of these rules may result in disqualification or removal from the platform.</li>
        </ul>
        <p>Enjoy the challenges, learn something new, and happy hacking! 😊</p>
      </section>
    </div>
  );
};

export default CheckAuth(RulesPage);