import React, { useEffect, useState, useContext } from 'react';
import { Table, message, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AuthContext } from '../hooks/AuthContext';
import '../styles/scoreboard.css'; // Create and import the CSS file for scoreboard page

const ScoreboardPage = () => {
  const [scoreboard, setScoreboard] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const fetchScoreboard = async () => {
      try {
        const token = localStorage.getItem('token'); // Ensure token is fetched from localStorage
        const response = await axios.get('/api/v1/regular/scoreboard', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setScoreboard(response.data);
      } catch (error) {
        console.error('Error fetching scoreboard:', error); // Log the error for debugging
        message.error('Error fetching scoreboard');
      }
    };

    fetchScoreboard();
  }, [authState.token]);

  const getFilteredScoreboard = () => {
    return scoreboard.filter(entry => 
      entry.username.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const columns = [
    {
      title: 'Place',
      dataIndex: 'place',
      key: 'place',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
    },
  ];

  return (
    <div className="content-container">
      <h1>Scoreboard</h1>
      <div className="scoreboard-header">
        <Input
          placeholder="Search by username"
          prefix={<SearchOutlined style={{ fontSize: '16px' }} />}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="search-input"
          allowClear
        />
      </div>
      <Table 
        dataSource={getFilteredScoreboard()} 
        columns={columns} 
        rowKey="place"
        pagination={false}
        className="scoreboard-table"
      />
    </div>
  );
};

export default ScoreboardPage;