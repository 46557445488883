// frontend/src/components/ScrambleText.js
import React from 'react';
import { useScramble } from 'use-scramble';

const ScrambleText = ({ text, speed = 0.5, scramble = 10, style = {} }) => {
  const { ref } = useScramble({ text, speed, scramble});

  return (
    <div ref={ref} style={style}>
      {text}
    </div>
  );
};

export default ScrambleText;