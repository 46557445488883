import React, { useState } from 'react';
import { Form, Input, InputNumber, Button, Upload, message, Tooltip } from 'antd';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CheckAuth from '../hooks/CheckAuth';
import '../styles/addAndEditTasks.css';

const AddTaskPage = () => {
  const [form] = Form.useForm();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('points', values.points);
      formData.append('description', values.description || '');
      formData.append('flag', values.flag);

      // Format hints array to match backend expectations
      const hints = values.hints?.map((hint, index) => ({
        text: hint.text,
        unlockTime: index === 0 ? 0 : (hint.unlockTime || 0) // First hint has no delay
      })) || [];
      formData.append('hints', JSON.stringify(hints));

      // Check for duplicate file names
      const fileNames = new Set();
      for (const fileObj of selectedFiles) {
        if (fileNames.has(fileObj.file.name)) {
          message.error(`Duplicate file name detected, files can't have same name!`);
          return;
        }
        fileNames.add(fileObj.file.name);
        formData.append('files', fileObj.file);
      }

      const token = localStorage.getItem('token');
      await axios.post('/api/v1/admin/create-tasks', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        }
      });

      message.success('Task created successfully');
      navigate('/admin/tasks');
    } catch (error) {
      message.error(error.response?.data?.message || 'Error creating task');
    }
  };

  // Add this function to handle file removal
  const handleRemoveFile = (fileToRemove) => {
    setSelectedFiles(prev => 
      prev.filter(f => f.file.uid !== fileToRemove.uid)
    );
  };

  return (
    <div className="content-container">
      <h1 className="edit-task-title">Add Task</h1>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item 
          name="name" 
          label="Task Name"
          rules={[{ required: true, message: 'Please enter task name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item 
          name="points" 
          label={
            <span>
              Points&nbsp;
              <Tooltip title="We recommend: Easy - 100, Medium - 200, Hard - 400 points">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          } 
          rules={[{ required: true, message: 'Please enter points' }]}
        >
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item 
          name="description" 
          label="Description" 
          rules={[{ required: true, message: 'Please enter description' }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.List 
          name="hints"
          rules={[
            {
              validator: async (_, hints) => {
                if (!hints) return Promise.resolve();
                if (hints.length > 5) {
                  return Promise.reject(new Error('Maximum 5 hints allowed'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {fields.map((field, index) => (
                <div key={field.key} className="hint-form-item">
                  <span className="hint-number">#{index + 1}</span>
                  <div style={{ display: 'flex', gap: '16px', width: '100%' }}>
                    {/* Hint Text Input */}
                    <div style={{ flex: 1 }}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'text']}
                        rules={[{ required: true, message: 'Please input hint text' }]}
                        label="Hint Text"
                      >
                        <Input.TextArea 
                          className="hint-textarea"
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          placeholder={`Enter hint #${index + 1}`}
                        />
                      </Form.Item>
                    </div>

                    {/* Unlock Delay Input */}
                    <div style={{ width: '200px' }}>
                      <Form.Item
                        name={[field.name, 'unlockTime']}
                        label="Unlock Delay (minutes)"
                      >
                        <InputNumber
                          min={0}
                          disabled={index === 0}
                          defaultValue={index === 0 ? 0 : null}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </div>

                    {/* Hint Actions */}
                    <div className="hint-actions">
                      <Button 
                        type="text"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                        className="hint-remove-button"
                      />
                      {fields.length > 1 && (
                        <div className="hint-move-buttons">
                          <Button 
                            type="primary"
                            onClick={() => move(index, index - 1)}
                            disabled={index === 0}
                            className="hint-move-button"
                          >
                            ↑
                          </Button>
                          <Button 
                            type="primary"
                            onClick={() => move(index, index + 1)}
                            disabled={index === fields.length - 1}
                            className="hint-move-button"
                          >
                            ↓
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {/* Add Hint Button */}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  disabled={fields.length >= 5}
                  style={{ width: '200px' }}
                >
                  Add Hint
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </div>
          )}
        </Form.List>

        <Form.Item 
          name="flag" 
          label={
            <span>
              Flag&nbsp;
              <Tooltip title="Don't forget to put flag answer in the correct format CTFMASTER{...}">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          } 
          rules={[{ required: true, message: 'Please enter flag' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item 
          label={
            <span>
              Add Files&nbsp;
              <Tooltip title="Allowed file types: .txt, .pdf, .png, .jpg, .jpeg, .gif, .zip, .7z, .rar, .tar, .gz, .bz2, .xz, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .odt, .ods, .odp, .rtf, .html, .htm, .xml, .json, .csv, .md, .yaml, .yml, .py, .js, .java, .c, .cpp, .h, .sh, .bat, .ps1, .php, .rb, .pl, .go, .rs, .swift, .kt, .dart, .scala, .lua, .sql, .db, .sqlite, .sqlite3, .log, .conf, .ini, .cfg, .properties, .env, .pem, .cer, .crt, .key, .asc, .pgp, .gpg, .bin, .exe, .dll, .so, .dylib, .apk, .ipa, .deb, .rpm, .img, .iso, .vmdk, .ova, .ovf, .qcow2, .vdi, .vhd, .vhdx, .pcap, .pcapng, .dd, .mem, .mp3, .wav, .ogg, .flac, .mp4, .mkv, .avi, .mov. Max size: 128MB">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Upload.Dragger
            multiple
            beforeUpload={(file) => {
              setSelectedFiles(prev => [...prev, {
                file,
                preview: URL.createObjectURL(file),
                name: file.name
              }]);
              return false;
            }}
            fileList={selectedFiles.map(f => f.file)} // Map to file objects
            onRemove={handleRemoveFile} // Add onRemove handler
            itemRender={(originNode, file, fileList) => (
              <div className="upload-list-item">
                <span className="file-name">{file.name}</span>
                <Button 
                  type="primary" 
                  danger
                  onClick={() => handleRemoveFile(file)} // Use handleRemoveFile
                >
                  Remove
                </Button>
              </div>
            )}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined style={{ color: '#1677ff', fontSize: '24px' }} />
            </p>
            <p style={{ color: '#FFFFFF' }}>Click or drag files to upload</p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create Task
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CheckAuth(AddTaskPage, true);