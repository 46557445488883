import React, { useEffect, useState } from 'react';
import { Button, Card, Row, Col, Statistic, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CheckAuth from '../hooks/CheckAuth';
import '../styles/adminDashboard.css';

const AdminDashboardPage = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState({
    total_users: 0,
    temporary_users: 0,
    total_tasks: 0,
    overall_hints_used: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('/api/v1/admin/stats', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        message.error('Failed to load statistics');
      }
    };

    fetchStats();
  }, []);

  const handleManageTasks = () => {
    navigate('/admin/tasks');
  };

  const handleManageUsers = () => {
    navigate('/admin/users');
  };

  return (
    <div className="admin-container">
      <h1>Admin Dashboard</h1>
      <Row gutter={[16, 16]} className="admin-stats">
        <Col span={6}>
          <Card>
            <Statistic
              title="Total System Users"
              value={stats.total_users}
              valueStyle={{ color: '#1677ff' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Temporary System Users"
              value={stats.temporary_users}
              valueStyle={{ color: '#1677ff' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Number of Tasks"
              value={stats.total_tasks}
              valueStyle={{ color: '#1677ff' }}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Overall Hints Used"
              value={stats.overall_hints_used}
              valueStyle={{ color: '#1677ff' }}
            />
          </Card>
        </Col>
      </Row>
      <div className="admin-buttons">
        <Button onClick={handleManageTasks} className="admin-button">
          Manage Tasks
        </Button>
        <Button onClick={handleManageUsers} className="admin-button">
          Manage Users
        </Button>
      </div>
    </div>
  );
};

export default CheckAuth(AdminDashboardPage, true);