import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import ProfilePage from '../Pages/ProfilePage';
import RulesPage from '../Pages/RulesPage';
import LoginPage from '../Pages/LoginPage';
import RegisterPage from '../Pages/RegisterPage';
import TasksPage from '../Pages/TasksPage';
import TaskDetailPage from '../Pages/TaskDetailPage';
import AdminTasksPage from '../Pages/AdminTasksPage';
import AddTaskPage from '../Pages/AddTaskPage';
import EditTaskPage from '../Pages/EditTaskPage';
import AdminUsersPage from '../Pages/AdminUsersPage';
import AdminDashboardPage from '../Pages/AdminDashboardPage';
import ScoreboardPage from '../Pages/ScoreboardPage';
import CreateTemporaryAccountPage from '../Pages/CreateTemporaryAccountPage';
import RequestResetPage from '../Pages/RequestResetPage';
import ResetPasswordPage from '../Pages/ResetPasswordPage';
import '../styles/content.css';

const Content = () => {
  return (
    <div style={{ background: 'white' }}>
      <div className="content-container">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/tasks" element={<TasksPage />} />
          <Route path="/tasks/:id" element={<TaskDetailPage />} />
          <Route path="/admin/tasks" element={<AdminTasksPage />} />
          <Route path="/admin/add-task" element={<AddTaskPage />} />
          <Route path="/admin/edit-task/:id" element={<EditTaskPage />} />
          <Route path="/admin/users" element={<AdminUsersPage />} />
          <Route path="/admin" element={<AdminDashboardPage />} />
          <Route path="/scoreboard" element={<ScoreboardPage />} />
          <Route path="/create-temporary-account" element={<CreateTemporaryAccountPage />} />
          <Route path="/request-reset" element={<RequestResetPage />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

export default Content;