import React, { useEffect, useState, useContext } from 'react';
import { Table, Button, Modal, message, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AuthContext } from '../hooks/AuthContext';
import CheckAuth from '../hooks/CheckAuth';
import '../styles/adminUsers.css';

const AdminUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/api/v1/admin/users', {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      message.error('Error fetching users');
    }
  };

  const getFilteredUsers = () => {
    return users.filter(user => 
      user.username.toLowerCase().includes(searchText.toLowerCase()) ||
      user.email.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const handleDeleteUser = async (userId) => {
    try {
      await Modal.confirm({
        title: 'Delete User',
        content: 'Are you sure you want to delete this user?',
        okText: 'Yes, Delete',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await axios.delete(`/api/v1/admin/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${authState.token}`,
            },
          });
          message.success('User deleted successfully');
          fetchUsers();
        }
      });
    } catch (error) {
      message.error('Error deleting user');
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="primary" 
          danger 
          onClick={() => handleDeleteUser(record.id)}
          disabled={record.role === 'admin'}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div className="content-container">
      <div className="admin-users-header">
        <h1>Manage Users</h1>
        <Input
          placeholder="Search users or emails"
          prefix={<SearchOutlined style={{ fontSize: '16px' }} />}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          className="search-input"
          allowClear
        />
      </div>
      <Table 
        dataSource={getFilteredUsers()} 
        columns={columns} 
        rowKey="id"
        className="users-table"
      />
    </div>
  );
};

export default CheckAuth(AdminUsersPage, true);