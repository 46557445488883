import React, { useState, useContext, useEffect } from 'react';
import { Menu } from 'antd';
import { 
  HomeOutlined, UserOutlined, KeyOutlined, 
  ReadOutlined, FlagOutlined, SettingOutlined,
  TrophyOutlined, OrderedListOutlined 
} from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { AuthContext } from '../hooks/AuthContext';
import '../styles/header.css';

const Header = () => {
  const [current, setCurrent] = useState('/');
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current location
  const { authState, logout } = useContext(AuthContext);

  useEffect(() => {
    // Update the current menu item based on the current path
    setCurrent(location.pathname);
  }, [location.pathname]); // Use location.pathname as a dependency

  const onClick = (e) => {
    if (e.key === '/logout') {
      logout();
      navigate('/login');
    } else {
      setCurrent(e.key);
      navigate(e.key);
    }
  };

  const mainItems = [
    {
      label: 'Home',
      key: '/',
      icon: <HomeOutlined />,
    },
    ...(authState.user?.is_admin ? [{
      label: 'Admin Dashboard',
      key: '/admin',
      icon: <SettingOutlined />,
    }] : []),
    ...(authState.isAuthenticated ? [
      {
        label: 'Rules',
        key: '/rules',
        icon: <ReadOutlined />,
      },
      {
        label: 'Tasks',
        key: '/tasks',
        icon: <FlagOutlined />,
      },
      {
        label: 'Scoreboard',
        key: '/scoreboard',
        icon: <OrderedListOutlined />,
      },
      {
        label: 'Profile',
        key: '/profile',
        icon: <UserOutlined />,
      },
      {
        label: 'Logout',
        key: '/logout',
        icon: <KeyOutlined />,
      },
    ] : [
      {
        label: 'Login',
        key: '/login',
        icon: <KeyOutlined />,
      },
      {
        label: 'Register',
        key: '/register',
        icon: <KeyOutlined />,
      },
    ]),
  ];

  return (
    <div className="header-container">
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={mainItems}
        theme="dark"
        className="left-menu"
      />
      {authState.isAuthenticated && (
        <div className="points-display">
          <TrophyOutlined />
          <span>{authState.user?.points || 0} Points</span>
        </div>
      )}
    </div>
  );
};

export default Header;