import React, { useEffect, useState, useContext } from 'react';
import { Button, message, Modal } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/AuthContext';
import CheckAuth from '../hooks/CheckAuth';
import '../styles/adminTasks.css';

const AdminTasksPage = () => {
  const [tasks, setTasks] = useState([]);
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('/api/v1/regular/tasks', {
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        });
        setTasks(response.data);
      } catch (error) {
        console.log('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, [authState.token]);

  const handleDeleteTask = async (id) => {
    try {
      await Modal.confirm({
        title: 'Delete Task',
        content: 'Are you sure you want to delete this task?',
        okText: 'Yes, Delete',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await axios.delete(`/api/v1/admin/tasks/${id}`, {
            headers: {
              Authorization: `Bearer ${authState.token}`,
            },
          });
          setTasks(tasks.filter(task => task.id !== id));
          message.success('Task deleted successfully');
        }
      });
    } catch (error) {
      message.error('Error deleting task');
    }
  };

  const handleAddTask = () => {
    navigate('/admin/add-task');
  };

  const handleEditTask = (id) => {
    navigate(`/admin/edit-task/${id}`);
  };

  return (
    <div className="content-container">
      <div className="admin-tasks-header">
        <h1>Admin Tasks</h1>
        <Button type="primary" onClick={handleAddTask} className="add-task-button">
          Add Task
        </Button>
      </div>
      <div className="admin-tasks-list">
        {tasks.map(task => (
          <div key={task.id} className="admin-task-item">
            <div className="task-info">
              <span className="task-name">{task.name}</span>
              <span className="task-points">{task.points} Points</span>
            </div>
            <div className="task-actions">
              <Button onClick={() => handleEditTask(task.id)}>
                Edit
              </Button>
              <Button type="primary" danger onClick={() => handleDeleteTask(task.id)}>
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckAuth(AdminTasksPage, true);