import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/AuthContext';
import '../styles/tasks.css';

const TasksPage = () => {
  const [tasks, setTasks] = useState([]);
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      if (authState.token) {
        try {
          const response = await axios.get('/api/v1/regular/tasks', {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          setTasks(response.data);
        } catch (error) {
          console.log('Error fetching tasks:', error);
        }
      }
    };

    fetchTasks();
  }, [authState.token]);

  const handleTaskClick = (taskId) => {
    navigate(`/tasks/${taskId}`);
  };

  if (!authState.isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-container">
      <div className="tasks-header">
        <h1 className="tasks-title">Available Tasks</h1>
      </div>
      <div className="tasks-list">
        {tasks.map((task) => {
          return (
            <div
              key={task.id}
              className={`task-item ${task.is_solved ? 'solved' : ''}`}
              onClick={() => handleTaskClick(task.id)}
            >
              <div className="task-info">
                <span className="task-name">{task.name}</span>
                <span className={`status-indicator ${task.is_solved ? 'solved' : 'unsolved'}`}>
                  {task.is_solved ? 'Solved' : 'Unsolved'}
                </span>
              </div>
              <div className="task-points">
                {task.points} Points
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TasksPage;