import React, { useState } from 'react';
import { Form, Input, Button, message, Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/createTemporaryAccount.css';

const { Title } = Typography;

const CreateTemporaryAccountPage = () => {
  const [generatedPassword, setGeneratedPassword] = useState(null);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post('/api/v1/regular/auth/create-temporary-account', {
        username: values.username,
      });
      setGeneratedPassword(response.data.password);
      setUsername(values.username);
      message.success('Temporary account created successfully');
    } catch (error) {
      message.error(error.response?.data?.message || 'Error creating temporary account');
    }
  };

  return (
    <div className="create-temp-account-container">
      <Card className="create-temp-account-card">
        <Title level={2} className="create-temp-account-title" style={{ marginTop: 0 }}>Create Temporary Account</Title>
        <ul className="disclaimer">
          <li>The account will be valid for 3 months and will be automatically deleted after that period.</li>
          <li>If the password is lost, it cannot be recovered.</li>
          <li>Temporary accounts will not be included in the scoreboard.</li>
        </ul>
        {!generatedPassword ? (
          <Form onFinish={handleSubmit} layout="vertical" className="create-temp-account-form">
            <Form.Item
              label={<span className="login-label">Username</span>}
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="create-account-button">
                Create Temporary Account
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className="generated-password">
            <p>Your temporary account has been created.</p>
            <p>The username <strong className="highlight-username">{username}</strong> has been created with the following password:</p>
            <p><strong className="highlight-password">{generatedPassword}</strong></p>
            <p>You can now login with the created username and given password.</p>
            <div className="redirect-links">
              <Button type="primary" onClick={() => navigate('/login')}>
                Go to Login
              </Button>
            </div>
          </div>
        )}
        <div className="redirect-links">
          <p>Already have an account? <Button type="link" onClick={() => navigate('/login')}>Login</Button></p>
          <p>Don't have an account? <Button type="link" onClick={() => navigate('/register')}>Register</Button></p>
        </div>
      </Card>
    </div>
  );
};

export default CreateTemporaryAccountPage;