// ResetPasswordPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import './../styles/loginPage.css'; // Import the CSS file

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [password, setPassword] = useState('');
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.post('/api/v1/regular/auth/validate-reset-password-token', { token });
        if (response.data.valid) {
          setTokenValid(true);
        } else {
          message.error('Invalid or expired token.');
          navigate('/login');
        }
      } catch (error) {
        message.error('Error validating token.');
        navigate('/login');
      }
    };

    validateToken();
  }, [token, navigate]);

  const onPasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setShowPasswordRequirements(true);
    setPasswordValidations({
      length: value.length >= 12,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handleSubmit = async (values) => {
    if (!Object.values(passwordValidations).every(Boolean)) {
      message.error('Password does not meet the security requirements.');
      return;
    }

    setLoading(true);
    try {
      await axios.post('/api/v1/regular/auth/reset-password', { ...values, token });
      message.success('Password reset successful.');
      navigate('/login');
    } catch (error) {
      message.error('Error resetting password.');
    } finally {
      setLoading(false);
    }
  };

  if (!tokenValid) {
    return <div>Loading...</div>;
  }

  return (
    <div className="login-container">
      <h1>Reset Password</h1>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="new_password"
          label={<span className="login-label">New Password</span>}
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password size="large" value={password} onChange={onPasswordChange} />
        </Form.Item>
        {showPasswordRequirements && (
          <div className="password-requirements">
            <p>Password must contain:</p>
            <ul>
              <li className={passwordValidations.length ? 'valid' : 'invalid'}>At least 12 characters</li>
              <li className={passwordValidations.uppercase ? 'valid' : 'invalid'}>At least one uppercase letter</li>
              <li className={passwordValidations.lowercase ? 'valid' : 'invalid'}>At least one lowercase letter</li>
              <li className={passwordValidations.number ? 'valid' : 'invalid'}>At least one number</li>
              <li className={passwordValidations.specialChar ? 'valid' : 'invalid'}>At least one special character</li>
            </ul>
          </div>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', height: '40px' }} loading={loading}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordPage;