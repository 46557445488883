// /components/footer.js
import React from 'react';

const Footer = () => {
  return (
    <div
    style={{
      textAlign: 'center',
      color: 'black',
      backgroundColor: 'white',
    }}>
    <footer>
      <p>Karolis Petniunas &copy; {new Date().getFullYear()} VU KNF. All rights reserved.</p>
    </footer>
    </div>
  );
};

export default Footer;