import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const CheckAuth = (WrappedComponent, adminOnly = false) => {
  const AuthWrapper = (props) => {
    const { authState, setAuthState } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const verifyAccess = async () => {
        if (!authState.isAuthenticated) {
          const token = localStorage.getItem('token');
          if (token) {
            try {
              const response = await axios.get('/api/v1/regular/user', {
                headers: { Authorization: `Bearer ${token}` },
              });
              setAuthState({
                isAuthenticated: true,
                user: response.data,
                token,
              });
              if (adminOnly && !response.data.is_admin) {
                throw new Error('Not an admin');
              }
            } catch (error) {
              console.log('Access denied:', error);
              navigate('/'); // Redirect to home if not authenticated or not an admin
            }
          } else {
            navigate('/'); // Redirect to home if no token is found
          }
        } else if (adminOnly && !authState.user.is_admin) {
          navigate('/'); // Redirect non-admins
        }
        setLoading(false);
      };

      verifyAccess();
    }, [authState, adminOnly, navigate, setAuthState]);

    if (loading) {
      return <div>Loading...</div>;
    }

    return <WrappedComponent {...props} />;
  };

  return AuthWrapper;
};

export default CheckAuth;