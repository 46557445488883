import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../hooks/AuthContext';
import { Card, Avatar, Typography, Row, Col, Form, Input, Button, message, Modal } from 'antd';
import { UserOutlined, MailOutlined, TrophyOutlined, LockOutlined } from '@ant-design/icons';
import CheckAuth from '../hooks/CheckAuth';
import '../styles/profile.css';

const { Title, Text } = Typography;

const ProfilePage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [points, setPoints] = useState(0);
  const [isTemporary, setIsTemporary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('/api/v1/regular/user', {
          headers: {
            Authorization: `Bearer ${authState.token}`,
          },
        });
        setUsername(response.data.username);
        setEmail(response.data.email || ''); // Handle absence of email
        setPoints(response.data.points);
        setIsTemporary(response.data.is_temporary); // Set isTemporary state
      } catch (error) {
        console.log('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [authState.token]);

  const onPasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setShowPasswordRequirements(true);
    setPasswordValidations({
      length: value.length >= 12,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handleChangePassword = async (values) => {
    if (!Object.values(passwordValidations).every(Boolean)) {
      message.error('Password does not meet the security requirements.');
      return;
    }

    if (values.newPassword !== values.confirmNewPassword) {
      message.error('New passwords do not match.');
      return;
    }

    setLoading(true);
    try {
      await axios.post('/api/v1/regular/change-password', values, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      message.success('Password changed successfully');
    } catch (error) {
      message.error(error.response?.data?.message || 'Error changing password');
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEmail = async (values) => {
    setLoading(true);
    try {
      await axios.post('/api/v1/regular/change-email', values, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        },
      });
      setEmail(values.newEmail);
      message.success('Email changed successfully');
    } catch (error) {
      message.error(error.response?.data?.message || 'Error changing email');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    Modal.confirm({
      title: 'Delete Account',
      content: 'Are you sure you want to delete your account? This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.delete('/api/v1/regular/delete-account', {
            headers: {
              Authorization: `Bearer ${authState.token}`,
            },
          });
          message.success('Account deleted successfully');
          window.location.href = '/login';
        } catch (error) {
          message.error(error.response?.data?.message || 'Error deleting account');
        }
      },
    });
  };

  return (
    <div className="profile-container">
      <Card className="profile-card">
        <Row gutter={[16, 16]} align="middle">
          <Col span={6}>
            <Avatar size={100} icon={<UserOutlined />} />
          </Col>
          <Col span={18}>
            <Title level={2} className="profile-title" style={{ marginTop: 0 }}>{username}</Title>
            {!isTemporary && email && <Text className="profile-text"><MailOutlined /> Email: {email}</Text>}<br />
            <Text className="profile-text"><TrophyOutlined /> Points: {points}</Text>
          </Col>
        </Row>
      </Card>

      {!isTemporary && (
        <Card className="profile-card">
          <Title level={3} style={{ marginTop: 0 }}>Change Email</Title>
          <Form onFinish={handleChangeEmail} layout="vertical">
            <Form.Item
              name="newEmail"
              label="New Email"
              rules={[
                { required: true, message: 'Please enter your new email' },
                { type: 'email', message: 'The input is not valid E-mail!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="confirmNewEmail"
              label="Confirm New Email"
              rules={[
                { required: true, message: 'Please confirm your new email' },
                { type: 'email', message: 'The input is not valid E-mail!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Change Email
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}

      <Card className="profile-card">
        <Title level={3} style={{ marginTop: 0 }}>Change Password</Title>
        <Form onFinish={handleChangePassword} layout="vertical">
          <Form.Item
            name="currentPassword"
            label="Current Password"
            rules={[{ required: true, message: 'Please enter your current password' }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[{ required: true, message: 'Please enter your new password' }]}
          >
            <Input.Password prefix={<LockOutlined />} value={password} onChange={onPasswordChange} />
          </Form.Item>
          {showPasswordRequirements && (
            <div className="password-requirements">
              <p>Password must contain:</p>
              <ul>
                <li className={passwordValidations.length ? 'valid' : 'invalid'}>At least 12 characters</li>
                <li className={passwordValidations.uppercase ? 'valid' : 'invalid'}>At least one uppercase letter</li>
                <li className={passwordValidations.lowercase ? 'valid' : 'invalid'}>At least one lowercase letter</li>
                <li className={passwordValidations.number ? 'valid' : 'invalid'}>At least one number</li>
                <li className={passwordValidations.specialChar ? 'valid' : 'invalid'}>At least one special character</li>
              </ul>
            </div>
          )}
          <Form.Item
            name="confirmNewPassword"
            label="Reenter New Password"
            rules={[{ required: true, message: 'Please reenter your new password' }]}
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <Card className="profile-card">
        <Title level={3} style={{ marginTop: 0 }}>Delete Account</Title>
        <Button type="primary" danger onClick={handleDeleteAccount}>
          Delete Account
        </Button>
      </Card>
    </div>
  );
};

export default CheckAuth(ProfilePage);