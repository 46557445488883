import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../hooks/AuthContext';
import { message, Button, Modal } from 'antd';
import { UnlockOutlined, DownloadOutlined, FlagOutlined, SendOutlined } from '@ant-design/icons';
import Confetti from 'react-confetti';
import '../styles/taskDetail.css';

const TaskDetailPage = () => {
  const { id } = useParams();
  const [task, setTask] = useState(null);
  const [flag, setFlag] = useState('');
  const { authState } = useContext(AuthContext);
  const [hints, setHints] = useState({ total: 0, unlocked: [] });
  const [unlocking, setUnlocking] = useState(false);
  const [waitMinutes, setWaitMinutes] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);  // New state for confetti
  const [showCongratsModal, setShowCongratsModal] = useState(false);  // New state for congrats modal

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/v1/regular/tasks/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTask(response.data);
      } catch (error) {
        console.error('Error fetching task:', error);
        message.error('Failed to load task details');
      }
    };

    const fetchHints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/v1/regular/tasks/${id}/hints`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setHints({
          total: response.data.total_hints || 0,
          unlocked: response.data.unlocked_hints || []
        });
      } catch (error) {
        console.error('Error fetching hints:', error);
        setHints({ total: 0, unlocked: [] });
      }
    };

    if (id) {
      fetchTask();
      fetchHints();
    }
  }, [id]);

  const handleSolveTask = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/v1/regular/tasks/${id}/solve`, { flag }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Trigger success feedback
      if (response.data.message === 'Task already solved, but correct flag') {
        message.success('Correct flag, but no points were given as the task was already solved.');
        return; // Exit the function early to prevent further actions
      } else if (response.data.message === 'Correct flag') {
        message.success('Correct flag!');
      } else {
        message.success('Task solved successfully!');
        setShowConfetti(true); // Show confetti
        setShowCongratsModal(true); // Show congratulations modal
      }

      // Update the task state
      setTask(response.data.task);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        message.error(`Error: ${error.response.data.message}`);
      } else {
        message.error('Error solving task');
      }
    }
  };

  const handleFileDownload = async (filePath) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/v1/regular/tasks/files/${filePath}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', task.files.find(f => f.path === filePath).name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      message.error('Error downloading file');
    }
  };

  const handleUnlockHint = async () => {
    try {
      setUnlocking(true);
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/v1/regular/tasks/${id}/hints/unlock`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHints(prev => ({
        ...prev,
        unlocked: [...prev.unlocked, response.data.hint]
      }));
      // Show success message when hint is unlocked
      message.success('Hint unlocked successfully!');
    } catch (error) {
      if (error.response?.data?.wait_minutes !== undefined) {
        setWaitMinutes(error.response.data.wait_minutes);
        // Show warning message with time remaining
        message.warning(
          `Next hint is locked. Please wait ${
            error.response.data.wait_minutes < 1 
              ? 'less than a minute' 
              : `${error.response.data.wait_minutes} ${
                  error.response.data.wait_minutes === 1 ? 'minute' : 'minutes'
                }`
          } before unlocking.`
        );
      } else {
        message.error(error.response?.data?.message || 'Failed to unlock hint');
      }
    } finally {
      setUnlocking(false);
    }
  };

  const isSolved = task?.solved_by?.includes(authState.user?.id);

  if (!task) {
    return <div>Loading...</div>;
  }

  return (
    <div className="task-detail-container">
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={true}
          numberOfPieces={200}
          style={{ position: 'fixed', top: 0, left: 0 }}
        />
      )}
      
      {/* Task header */}
      <div className="task-header">
        <h1>Task: {task.name} | Points: {task.points}</h1>
        <div className={`status-badge ${isSolved ? 'status-solved' : 'status-unsolved'}`}>
          {isSolved ? 'Solved' : 'Unsolved'}
        </div>
      </div>

      {/* Task info */}
      <div className="task-info">
        <p><strong>Description:</strong> {task.description}</p>
      </div>

      {/* Files section - only show if files exist */}
      {task.files && task.files.length > 0 && (
        <div className="files-section">
          <h2>Task Files</h2>
          <ul>
            {task.files.map((file, index) => (
              <li key={index}>
                <button 
                  className="download-button"
                  onClick={() => handleFileDownload(file.path)}
                >
                  Download {file.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Hints section - only show if hints exist */}
      {hints.total > 0 && (
        <div className="hints-section">
          <h2>Hints ({hints.unlocked.length}/{hints.total})</h2>
          <div className="hints-container">
            {hints.unlocked.map((hint, index) => (
              <div key={index} className="hint-item">
                <div className="hint-content">
                  <div className="hint-text">#{index + 1} {hint.text}</div>
                </div>
              </div>
            ))}
            {hints.unlocked.length < hints.total && (
              <div>
                <Button 
                  onClick={handleUnlockHint}
                  loading={unlocking}
                  icon={<UnlockOutlined />}
                  className="unlock-hint-button"
                >
                  Unlock Next Hint
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Flag submission */}
      <div className="flag-section">
        <h2>Submit Flag</h2>
        <div className="flag-input-group">
          <input
            type="text"
            className="flag-input"
            placeholder="Enter flag"
            value={flag}
            onChange={(e) => setFlag(e.target.value)}
          />
          <button
            onClick={handleSolveTask}
            className={`submit-button ${isSolved ? 'solved-button' : ''}`}
          >
            {isSolved ? 'Task is already solved' : 'Submit Flag'}
          </button>
        </div>
      </div>

      {/* Modal for congratulations */}
      <Modal
        title="Congratulations!"
        visible={showCongratsModal}
        onCancel={() => {
          setShowCongratsModal(false);
          window.location.reload(); // Refresh the page
        }}
        footer={[
          <Button key="ok" onClick={() => {
            setShowCongratsModal(false);
            window.location.reload(); // Refresh the page
          }}>
            OK
          </Button>
        ]}
      >
        <p>🎉 Well done! You’ve successfully solved the task and earned {task.points} points!</p>
      </Modal>
    </div>
  );
};

export default TaskDetailPage;