import React, { useContext } from 'react';
import { AuthContext } from '../hooks/AuthContext';
import ScrambleText from '../hooks/ScrambleText';

const HomePage = () => {
  const { authState } = useContext(AuthContext);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '70vh',
      textAlign: 'center',
    },
    text: {
      fontSize: '50px',
      fontWeight: 'bold',
      color: '#FFFFFF',
      marginBottom: '20px',
    },
    subText: {
      fontSize: '30px',
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
  };

  return (
    <div style={styles.container}>
      {authState.isAuthenticated ? (
        <ScrambleText text={`Welcome, ${authState.user.username}!`} style={styles.text} />
      ) : (
        <>
          <div style={styles.text}>Welcome to CTFMASTER</div>
          <ScrambleText text="Your Capture The Flag Journey Starts Here!" style={styles.subText} />
        </>
      )}
    </div>
  );
};

export default HomePage;