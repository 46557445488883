// LoginPage.js
import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RedirectIfAuthenticated from '../hooks/RedirectIfAuthenticated';
import './../styles/loginPage.css'; // Import the CSS file

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post('/api/v1/regular/auth/login', {
        username: values.username,
        password: values.password,
      });
      localStorage.setItem('token', response.data.token);
      message.success('Login successful!');
      window.location.href = '/';  // This will cause a full page reload
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        message.error(`Login failed: ${error.response.data.message}`);
      } else {
        message.error('Login failed: An unknown error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-container">
      <h1>Login Page</h1>
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<span className="login-label">Username</span>}
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={<span className="login-label">Password</span>}
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox className="remember-me-button">Remember me</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', height: '40px' }} loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
      <div className="register-option">
        <p>Don't have an account?</p>
        <Button type="link" onClick={() => navigate('/register')}>
          Create one here
        </Button>
      </div>
      <div className="forgot-password-option">
        <p>Forgot your password?</p>
        <Button type="link" onClick={() => navigate('/request-reset')}>
          Reset it here
        </Button>
      </div>
    </div>
  );
};

export default RedirectIfAuthenticated(LoginPage);