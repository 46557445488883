import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem('token'), // Load token on initialization
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (authState.token) {
        try {
          const response = await axios.get('/api/v1/regular/user', {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          setAuthState({
            isAuthenticated: true,
            user: response.data,
            token: authState.token,
          });
        } catch (error) {
          console.log('Error fetching user details:', error);
          setAuthState({
            isAuthenticated: false,
            user: null,
            token: null,
          });
          localStorage.removeItem('token');
        }
      }
    };

    fetchUserDetails();
  }, [authState.token]);

  const login = (userData, token) => {
    localStorage.setItem('token', token);
    setAuthState({
      isAuthenticated: true,
      user: userData,
      token,
    });
  };

  const logout = async () => {
    try {
      await axios.post('/api/v1/regular/auth/logout', {}, {
        headers: { Authorization: `Bearer ${authState.token}` },
      });
    } catch (error) {
      console.log('Error logging out:', error);
    } finally {
      localStorage.removeItem('token');
      setAuthState({
        isAuthenticated: false,
        user: null,
        token: null,
      });
    }
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};