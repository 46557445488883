// RequestResetPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, message } from 'antd';
import './../styles/loginPage.css'; // Import the CSS file

const RequestResetPage = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await axios.post('/api/v1/regular/auth/request-reset', values);
      message.success('If the email is registered, you will receive a reset link.');
    } catch (error) {
      message.error('Error requesting password reset.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h1>Request Password Reset</h1>
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="email"
          label={<span className="login-label">Email</span>}
          rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
        >
          <Input size="large"/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', height: '40px' }} loading={loading}>
            Request Password Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RequestResetPage;