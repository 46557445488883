import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import RedirectIfAuthenticated from '../hooks/RedirectIfAuthenticated';
import './../styles/loginPage.css'; // Import the CSS file

const RegisterPage = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });

  const onPasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setShowPasswordRequirements(true);
    setPasswordValidations({
      length: value.length >= 12,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const onFinish = async (values) => {
    if (!Object.values(passwordValidations).every(Boolean)) {
      message.error('Password does not meet the security requirements.');
      return;
    }

    try {
      const response = await axios.post('/api/v1/regular/auth/register', {
        username: values.username,
        password: values.password,
        email: values.email,
      });
      message.success('Registration successful!');
      navigate('/login');  // Use navigate to redirect
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        message.error(`Registration failed: ${error.response.data.message}`);
      } else {
        message.error('Registration failed: An unknown error occurred.');
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-container">
      <h1>Register Page</h1>
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<span className="login-label">Username</span>}
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={<span className="login-label">Email</span>}
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label={<span className="login-label">Password</span>}
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password size="large" value={password} onChange={onPasswordChange} />
        </Form.Item>
        {showPasswordRequirements && (
          <div className="password-requirements">
            <p>Password must contain:</p>
            <ul>
              <li className={passwordValidations.length ? 'valid' : 'invalid'}>At least 12 characters</li>
              <li className={passwordValidations.uppercase ? 'valid' : 'invalid'}>At least one uppercase letter</li>
              <li className={passwordValidations.lowercase ? 'valid' : 'invalid'}>At least one lowercase letter</li>
              <li className={passwordValidations.number ? 'valid' : 'invalid'}>At least one number</li>
              <li className={passwordValidations.specialChar ? 'valid' : 'invalid'}>At least one special character</li>
            </ul>
          </div>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', height: '40px' }}>
            Register
          </Button>
        </Form.Item>
      </Form>
      <div className="temporary-account-option">
        <p>Or</p>
        <Button type="link" onClick={() => navigate('/create-temporary-account')}>
          Create a Temporary Account
        </Button>
      </div>
      <div className="login-option">
        <p>Already got an account?</p>
        <Button type="link" onClick={() => navigate('/login')}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default RedirectIfAuthenticated(RegisterPage);