import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const RedirectIfAuthenticated = (WrappedComponent) => {
  const AuthWrapper = (props) => {
    const { authState } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      if (authState.isAuthenticated) {
        navigate('/');
      }
    }, [authState.isAuthenticated, navigate]);

    return <WrappedComponent {...props} />;
  };

  return AuthWrapper;
};

export default RedirectIfAuthenticated;